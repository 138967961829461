<template>
  <div class="searchList">
  </div>
</template>

<script>
export default {
  name: 'VabIcon',
  components: {},
  props: {
    configList: {
      type: Array,
      default: () => {
        return []
      },
    },
    slotName: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      searchParam: {},
      nxpSearchParam: {
        list: [
        ]
      },
    }
  },
  watch: {
    configList: {
      handler: function (newV, oldV) {
      },
      deep: true, //深度监听设置为 true
    },
  },
  created () {
  },
  mounted () {
  },
  methods: {

  },
}
</script>

<style lang="scss">

</style>
