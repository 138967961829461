var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.theme.showTheme
    ? _c(
        "span",
        [
          _c("vab-icon", {
            attrs: { icon: "brush-2-line" },
            on: { click: _vm.handleOpenTheme },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }