var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-cascader", {
    attrs: {
      size: _vm.size,
      options: _vm.options,
      placeholder: _vm.placeholder,
      parameters: _vm.parameters,
    },
    on: { change: _vm.dynamicHandleChange },
    model: {
      value: _vm.provinces,
      callback: function ($$v) {
        _vm.provinces = $$v
      },
      expression: "provinces",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }