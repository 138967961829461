var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vab-layout-common",
      class: {
        fixed: _vm.fixedHeader,
        "no-tabs-bar": !_vm.showTabs,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "vab-layout-header",
          class: {
            "fixed-header": _vm.fixedHeader,
          },
        },
        [
          _c("vab-header", { attrs: { layout: "common" } }),
          _c(
            "div",
            [
              _c("vab-side-bar", { attrs: { layout: "common" } }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTabs,
                      expression: "showTabs",
                    },
                  ],
                  staticClass: "vab-main",
                  class: {
                    "is-collapse-main": _vm.collapse,
                  },
                },
                [_c("vab-tabs", { attrs: { layout: "common" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "vab-main main-padding",
          class: {
            "is-collapse-main": _vm.collapse,
          },
        },
        [_c("vab-app-main")],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }