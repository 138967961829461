var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goods-item tree-item-box boxshadow padded-10" },
    [
      _c(
        "div",
        {
          staticClass: "pos-relative",
          on: {
            click: function ($event) {
              _vm.is_preview ? _vm.showView(_vm.item) : function () {}
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "demo-image__lazy" },
            [
              _c("el-image", {
                key: _vm.item.cover_image,
                staticStyle: { width: "100%", height: "180px" },
                attrs: { src: _vm.item.cover_image, lazy: "" },
              }),
            ],
            1
          ),
          (_vm.is_show_specification && _vm.item.xdj) ||
          _vm.item.height ||
          _vm.item.gf
            ? _c(
                "div",
                {
                  staticClass: "pos-absolute pos-b0 w-per-100",
                  staticStyle: { background: "rgba(0, 0, 0, 0.45)" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "padded-6 fs-12 c-FFF spanEllipsis" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.item.xdj_status == 1 &&
                              (_vm.item.xdj || _vm.item.xdj > 0)
                              ? "Φ" + _vm.item.xdj
                              : _vm.item.xdj_status == 2 &&
                                (_vm.item.xdj || _vm.item.xdj > 0)
                              ? "D" + _vm.item.xdj
                              : ""
                          )
                        ),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.item.xdj || _vm.item.xdj > 0) &&
                              (_vm.item.height || _vm.item.height > 0)
                              ? "/"
                              : ""
                          )
                        ),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.item.height || _vm.item.height > 0
                              ? "H" + _vm.item.height
                              : ""
                          )
                        ),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.item.gf || _vm.item.gf > 0) &&
                              (_vm.item.height || _vm.item.height > 0)
                              ? "/"
                              : ""
                          )
                        ),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.item.gf || _vm.item.gf > 0
                              ? "P" + _vm.item.gf
                              : ""
                          )
                        ),
                      ]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "font-bold padded-l10 padded-t10 spanEllipsis" },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.item.item_name || _vm.item.tree_type_name)),
          ]),
        ]
      ),
      _vm.is_show_price
        ? _c(
            "div",
            { staticClass: "padded-l10 padded-b6" },
            [
              _c(
                "div",
                {
                  staticClass: "flex-box-item padded-t6 tableCell spanEllipsis",
                },
                [
                  _c("span", { staticClass: "fs-14 c-F30" }, [
                    _vm._v(
                      _vm._s(
                        !parseInt(_vm.item.price)
                          ? "面议"
                          : "￥" + parseInt(_vm.item.price)
                      )
                    ),
                  ]),
                  _c("i", {
                    staticClass: "el-icon-location-outline",
                    attrs: { color: "#999", size: "8" },
                  }),
                  _c("span", { staticClass: "fs-12 c-999" }, [
                    _vm._v(_vm._s(_vm.item.location)),
                  ]),
                ]
              ),
              _vm._t("number-box"),
            ],
            2
          )
        : _vm._e(),
      _vm.is_show_updated
        ? _c("div", { staticClass: "flex-box pos-relative padded-l10" }, [
            _c("div", { staticClass: "flex-box-item c-999 fs-12" }, [
              _vm._v(_vm._s(_vm.item.updated_at)),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }