import request from '@/utils/request'
// 获取用户信息
export function getUserInfo (data) {
  return request({
    url: '/admin/userInfo',
    method: 'post',
    data,
  })
}
// 密码登录
export function pwdLogin (data) {
  return request({
    url: '/admin/adminLoginAccount',
    method: 'post',
    data,
  })
}
// 注册
export function register (data) {
  return request({
    url: '/admin/register',
    method: 'post',
    data,
  })
}

export function socialLogin (data) {
  return request({
    url: '/socialLogin',
    method: 'post',
    data,
  })
}
// 退出
export function logout (data) {
  return request({
    url: '/logout',
    method: 'get',
  })
}
// 苗木列表
export function querySeedingDataList (data) {
  return request({
    url: '/admin/seedling-list',
    method: 'post',
    data,
  })
}

// 植物选型列表
export function queryTypeSelectionDataList (data) {
  return request({
    url: '/treeTypeSelection/record-list',
    method: 'post',
    data,
  })
}

// 项目列表
export function queryProjectDataList (data) {
  return request({
    url: '/admin/project-list',
    method: 'post',
    data,
  })
}
// 报告列表
export function queryReportDataList (data) {
  return request({
    url: '/admin/report-list',
    method: 'post',
    data,
  })
}
// 报告详情
export function queryReportDetail (data) {
  return request({
    url: '/cloud-report-map/record-detail',
    method: 'post',
    data,
  })
}
// 重新生成
export function againCreateReport (data) {
  return request({
    url: '/tree-map/report-again-create',
    method: 'post',
    data,
  })
}
// 编辑报告
export function updateReportDataList (data) {
  return request({
    url: '/tree-map/report-update',
    method: 'post',
    data,
  })
}
// 删除报告
export function delReportDataList (data) {
  return request({
    url: '/tree-map/report-delete',
    method: 'post',
    data,
  })
}
// 短信列表
export function querySmsDataList (data) {
  return request({
    url: '/admin/sms-list',
    method: 'post',
    data,
  })
}
// 操作日志列表
export function queryActionLogDataList (data) {
  return request({
    url: '/admin/action-log-list',
    method: 'post',
    data,
  })
}
// 分享日志列表
export function queryShareLogDataList (data) {
  return request({
    url: '/admin/share-log-list',
    method: 'post',
    data,
  })
}
// 登录日志列表
export function queryLoginLogDataList (data) {
  return request({
    url: '/admin/login-log-list',
    method: 'post',
    data,
  })
}
// 批量删除登录日志
export function delLoginLogDataItem (data) {
  return request({
    url: '/admin/login-log-delete',
    method: 'post',
    data,
  })
}
// 收集数据列表
export function queryCollectDataList (data) {
  return request({
    url: '/admin/collect-list',
    method: 'post',
    data,
  })
}
// 收集数据列表
export function delCollectDataItem (data) {
  return request({
    url: '/admin/collect-delete-list',
    method: 'post',
    data,
  })
}

// 系统日志列表
export function queryLogDataList (data) {
  return request({
    url: '/admin/err-list',
    method: 'post',
    data,
  })
}
// 批量删除系统日志
export function delErrDataItem (data) {
  return request({
    url: '/admin/err-delete-list',
    method: 'post',
    data,
  })
}
// 参数列表
export function queryStaticDataList (data) {
  return request({
    url: '/static-conf/list',
    method: 'post',
    data,
  })
}
// 参数配置
export function saveStaticDataItem (data) {
  return request({
    url: '/static-conf/save',
    method: 'post',
    data,
  })
}
// 更改密码
export function resetDataItem (data) {
  return request({
    url: '/admin/reset-password',
    method: 'post',
    data,
  })
}


