var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vab-tabs" },
    [
      _vm.layout === "common" ? _c("vab-fold") : _vm._e(),
      _c(
        "el-tabs",
        {
          staticClass: "vab-tabs-content",
          class:
            ((_obj = {}),
            (_obj["vab-tabs-content-" + _vm.theme.tabsBarStyle] = true),
            _obj),
          attrs: { type: "card" },
          on: {
            "tab-click": _vm.handleTabClick,
            "tab-remove": _vm.handleTabRemove,
          },
          model: {
            value: _vm.tabActive,
            callback: function ($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive",
          },
        },
        _vm._l(_vm.visitedRoutes, function (item) {
          return _c(
            "el-tab-pane",
            {
              key: item.path,
              attrs: { closable: !_vm.isNoClosable(item), name: item.path },
            },
            [
              _c(
                "span",
                {
                  staticStyle: { display: "inline-block" },
                  attrs: { slot: "label" },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.openMenu($event, item)
                    },
                  },
                  slot: "label",
                },
                [
                  _vm.theme.showTabsBarIcon
                    ? [
                        item.meta && item.meta.icon
                          ? _c("vab-icon", {
                              attrs: {
                                icon: item.meta.icon,
                                "is-custom-svg": item.meta.isCustomSvg,
                              },
                            })
                          : _c("vab-icon", {
                              attrs: { icon: item.parentIcon },
                            }),
                      ]
                    : _vm._e(),
                  _c("span", [
                    _vm._v(_vm._s(_vm.translateTitle(item.meta.title))),
                  ]),
                ],
                2
              ),
            ]
          )
        }),
        1
      ),
      _c(
        "el-dropdown",
        {
          on: {
            command: _vm.handleCommand,
            "visible-change": _vm.handleVisibleChange,
          },
          scopedSlots: _vm._u([
            {
              key: "dropdown",
              fn: function () {
                return [
                  _c(
                    "el-dropdown-menu",
                    { staticClass: "tabs-more" },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "closeOthersTabs" } },
                        [
                          _c("vab-icon", { attrs: { icon: "close-line" } }),
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.translateTitle("关闭其他")) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "closeLeftTabs" } },
                        [
                          _c("vab-icon", {
                            attrs: { icon: "arrow-left-line" },
                          }),
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.translateTitle("关闭左侧")) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "closeRightTabs" } },
                        [
                          _c("vab-icon", {
                            attrs: { icon: "arrow-right-line" },
                          }),
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.translateTitle("关闭右侧")) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "closeAllTabs" } },
                        [
                          _c("vab-icon", { attrs: { icon: "close-line" } }),
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.translateTitle("关闭全部")) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "span",
            {
              staticClass: "vab-tabs-more",
              class: { "vab-tabs-more-active": _vm.active },
            },
            [
              _c("span", { staticClass: "vab-tabs-more-icon" }, [
                _c("i", { staticClass: "box box-t" }),
                _c("i", { staticClass: "box box-b" }),
              ]),
            ]
          ),
        ]
      ),
      _vm.visible
        ? _c(
            "ul",
            {
              staticClass:
                "contextmenu el-dropdown-menu el-dropdown-menu--small",
              style: { left: _vm.left + "px", top: _vm.top + "px" },
            },
            [
              _c(
                "li",
                {
                  staticClass: "el-dropdown-menu__item",
                  class: { "is-disabled": _vm.visitedRoutes.length === 1 },
                  on: { click: _vm.closeOthersTabs },
                },
                [
                  _c("vab-icon", { attrs: { icon: "close-line" } }),
                  _c("span", [_vm._v(_vm._s(_vm.translateTitle("关闭其他")))]),
                ],
                1
              ),
              _c(
                "li",
                {
                  staticClass: "el-dropdown-menu__item",
                  class: {
                    "is-disabled": !_vm.visitedRoutes.indexOf(_vm.hoverRoute),
                  },
                  on: { click: _vm.closeLeftTabs },
                },
                [
                  _c("vab-icon", { attrs: { icon: "arrow-left-line" } }),
                  _c("span", [_vm._v(_vm._s(_vm.translateTitle("关闭左侧")))]),
                ],
                1
              ),
              _c(
                "li",
                {
                  staticClass: "el-dropdown-menu__item",
                  class: {
                    "is-disabled":
                      _vm.visitedRoutes.indexOf(_vm.hoverRoute) ===
                      _vm.visitedRoutes.length - 1,
                  },
                  on: { click: _vm.closeRightTabs },
                },
                [
                  _c("vab-icon", { attrs: { icon: "arrow-right-line" } }),
                  _c("span", [_vm._v(_vm._s(_vm.translateTitle("关闭右侧")))]),
                ],
                1
              ),
              _c(
                "li",
                {
                  staticClass: "el-dropdown-menu__item",
                  on: { click: _vm.closeAllTabs },
                },
                [
                  _c("vab-icon", { attrs: { icon: "close-line" } }),
                  _c("span", [_vm._v(_vm._s(_vm.translateTitle("关闭全部")))]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }