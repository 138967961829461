var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    {
      staticClass: "vab-column-bar-container",
      class:
        ((_obj = {
          "is-collapse": _vm.collapse,
        }),
        (_obj["vab-column-bar-container-" + _vm.theme.columnStyle] = true),
        _obj),
    },
    [
      _c("vab-logo"),
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "left" },
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.extra.first,
            callback: function ($$v) {
              _vm.$set(_vm.extra, "first", $$v)
            },
            expression: "extra.first",
          },
        },
        [
          _vm._l(_vm.handleRoutes, function (route, index) {
            var _obj
            return [
              _c(
                "el-tab-pane",
                { key: index + route.name, attrs: { name: route.name } },
                [
                  _c("template", { slot: "label" }, [
                    _c(
                      "div",
                      {
                        staticClass: "vab-column-grid",
                        class:
                          ((_obj = {}),
                          (_obj[
                            "vab-column-grid-" + _vm.theme.columnStyle
                          ] = true),
                          _obj),
                        attrs: { title: _vm.translateTitle(route.meta.title) },
                      },
                      [
                        _c(
                          "div",
                          [
                            route.meta.icon
                              ? _c("vab-icon", {
                                  attrs: {
                                    icon: route.meta.icon,
                                    "is-custom-svg": route.meta.isCustomSvg,
                                  },
                                })
                              : _vm._e(),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.translateTitle(route.meta.title)) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ]
          }),
        ],
        2
      ),
      _c(
        "el-menu",
        {
          attrs: {
            "background-color": _vm.variables["column-second-menu-background"],
            "default-active": _vm.activeMenu,
            "default-openeds": _vm.defaultOpeneds,
            mode: "vertical",
            "unique-opened": _vm.uniqueOpened,
          },
        },
        [
          _c("el-divider", [
            _vm._v(
              " " + _vm._s(_vm.translateTitle(_vm.handleGroupTitle)) + " "
            ),
          ]),
          _vm._l(_vm.handlePartialRoutes, function (route) {
            return [
              route.meta && !route.meta.hidden
                ? _c("vab-menu", { key: route.path, attrs: { item: route } })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }