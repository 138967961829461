import Big from './big'
import { luhnCheck } from '@/utils/index'
import store from '@/store'
// 数据加减乘除精确计算
export const calculator = {
    operation(key, num1, num2, d) {
        const s1 =
            num1 === '0' || num1 === '' || num1 === null || isNaN(num1)
                ? Big(0)
                : Big(num1)
        const s2 =
            num2 === '0' || num2 === '' || num2 === null || isNaN(num1)
                ? Big(0)
                : Big(num2)
        if (
            key === 'div' &&
            (['', '0', 0].includes(num2) || num2 === null || isNaN(num1))
        )
            return '0'
        const res = s1[key](s2)
        const dec = d
            ? res
                .toFixed(d)
                .split('.')[1]
                .split('')
                .reverse()
                .join('')
                .replace(/\b(0+)/gi, '')
                .split('')
                .reverse()
                .join('')
            : ''
        return d !== null && !isNaN(d)
            ? res.toFixed(d).split('.')[0] + (dec ? `.${dec}` : '')
            : res.toString() === '0'
                ? ''
                : res.toString()
    },
    add: (num1 = 0, num2 = 0, d = null) =>
        calculator.operation('plus', num1, num2, d),
    sub: (num1 = 0, num2 = 0, d = null) =>
        calculator.operation('minus', num1, num2, d),
    mul: (num1 = 0, num2 = 0, d = null) =>
        calculator.operation('times', num1, num2, d),
    div: (num1 = 0, num2 = 0, d = null) =>
        calculator.operation('div', num1, num2, d),
}

export const isOptionEquals = (firstOption, secondOption) => {
    var aProps = Object.getOwnPropertyNames(firstOption)
    var bProps = Object.getOwnPropertyNames(secondOption)
    if (aProps.length !== bProps.length) return false
    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i]

        if (firstOption[propName] !== secondOption[propName]) {
            return false
        }
    }

    return true
}
// 表单校验规则(暂无使用)
export const validatorInput = (value = '', props, callback) => {
    console.log('validatorInput', value, props)
    if (!value && value !== 0) value = ''
    const {
        intlength = 0,
        declength = 0,
        label = '该项',
        plus = true,
        string = false,
        number = false,
        required = true,
        max = '',
        min = '',
        eq = false,
        negative = false,
        message = '',
        zero = false,
        maxLabel = '',
        minLabel = '',
        polling,
        calculator = false,
    } = props

    function getMessage(params) {
        return message ? message : params
    }

    if (required) {
        if (!value && Object.prototype.toString.call(value) !== '[object Number]')
            return callback(new Error(getMessage(`[${label}]为必填项`)))
    } else {
        if (value === '' || value === undefined) return callback()
    }

    if (string) {
        if (!intlength) return callback()
        if (value.toString().length > intlength)
            return callback(
                new Error(getMessage(`[${label}]最多输入${intlength}位字符`))
            )
    }

    if (number) {
        const [int, dec] = value.toString().split('.')
        if (!(value % 1 <= 1)) return callback(new Error(`请输入正确的数字`))
        if (calculator) {
            if (intlength && int && int.length > intlength)
                return callback(
                    new Error(getMessage(`[${label}]计算超过数据长度${intlength}位整数`))
                )
            if (dec && declength && dec.length > declength)
                return callback(
                    new Error(getMessage(`[${label}]计算超过数据长度${declength}位小数`))
                )
        }
        if (zero && Number(value) === 0) return callback()
        if (plus && Number(value) <= 0)
            return callback(new Error(getMessage(`[${label}]应为正数`)))
        if (negative && Number(value) >= 0)
            return callback(new Error(getMessage(`[${label}]应为负数`)))
        if (dec && Number(value) % 1 !== 0 && declength === 0)
            return callback(new Error(getMessage(`[${label}]应为整数`)))
        if (intlength && int && int.length > intlength)
            return callback(
                new Error(getMessage(`[${label}]最多输入${intlength}位整数`))
            )
        if (declength && dec && dec.length > declength)
            return callback(
                new Error(getMessage(`[${label}]最多输入${declength}位小数`))
            )
        if (eq) {
            if (max !== '' && Number(value) > max)
                return callback(
                    new Error(
                        getMessage(`[${label}]的值应小于等于${maxLabel ? maxLabel : max}`)
                    )
                )
            if (min !== '' && Number(value) < min)
                return callback(
                    new Error(
                        getMessage(`[${label}]的值应大于等于${minLabel ? minLabel : min}`)
                    )
                )
        } else {
            if (max !== '' && Number(value) >= max)
                return callback(
                    new Error(
                        getMessage(`[${label}]的值应小于${maxLabel ? maxLabel : max}`)
                    )
                )
            if (min !== '' && Number(value) <= min)
                return callback(
                    new Error(
                        getMessage(`[${label}]的值应大于${minLabel ? minLabel : min}`)
                    )
                )
        }
    }

    if (polling) polling()

    return callback()
}
// 输入长度校验
export const validatorNumber = (value = '', props, callback) => {
    // 定义默认值
    const {
        intlength = 0, //默认字节长度
        declength = 0, //默认小数点后的数字节长度
        label = '该项',
        plus = true, //默认正整数
        string = false, //默认不是字符串类型
        number = false, //默认不是数字类型
        required = false, //默认非必填
        max = 0, //默认最大值
        min = 0, //默认最小值
        equal = false,//是否相等
        message = '', //默认错误提示信息
        zero = false, //默认不能为0
        phone = false, //默认不是手机号
        bankNumber = false, //默认不是卡号
        polling,
        calculator = false,
    } = props

    function getMessage(params) {
        return message ? message : params
    }
    // 是否必填
    if (required) {
        if (
            (!value && Object.prototype.toString.call(value) !== '[object Number]') ||
            value.toString().length == 0
        )
            return callback(new Error(getMessage(`[${label}]为必填项`)))
    } else {
        if (value === '' || value === null || value === undefined) return callback()
    }

    // 是否是字符串
    if (string) {
        if (value.toString().length > intlength)
            return callback(
                new Error(getMessage(`[${label}]最多输入${intlength}位字符`))
            )
    }
    // 手机校验
    if (phone) {
        var myreg = /^0\d{2,3}-?\d{7,8}$/ //座机
        var phmyreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/ //手机
        if (!myreg.test(value) && !phmyreg.test(value)) {
            return callback(new Error('请输入正确的号码！'))
        }
    }
    // 银行卡号校验
    if (bankNumber) {
        if (value.length < 15) {
            return callback(new Error('请正确输入银行卡号！'))
        }
        //检查银行卡号方案一：
        // if (value.length < 16 || value.length > 19) {
        //     return callback(new Error('银行卡号长度必须在16到19之间'))
        // }
        // var num = /^\d*$/;//全数字
        // if (!num.exec(value)) {
        //     return callback(new Error('银行卡号必须全为数字'))
        // }
        // var strBin = "10,18,30,35,37,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,58,60,62,65,68,69,84,87,88,94,95,98,99";
        // if (strBin.indexOf(value.substring(0, 2)) == -1) {
        //     return callback(new Error('银行卡号开头6位不符合规范'))
        // }
        // if (!luhnCheck(value)) {
        //     return callback(new Error('请正确输入银行卡号！'))
        // }
    }
    // 是否是数字类型
    if (number) {
        // 是否是合法数字
        let numReg = /^(\-|\+)?\d+(\.\d+)?$/
        let numRe = new RegExp(numReg)
        if (!numRe.test(value)) {
            callback(new Error('请输入合法数字'))
        }

        const [int, dec] = value.toString().split('.')
        if (calculator) {
            if (intlength && int && int.length > intlength)
                return callback(
                    new Error(getMessage(`[${label}]计算超过数据长度${intlength}位整数`))
                )
            if (declength && dec && dec.length > declength)
                return callback(
                    new Error(getMessage(`[${label}]计算超过数据长度${declength}位小数`))
                )
        }

        if (zero && value == 0) return callback()
        if (plus && value <= 0)
            return callback(new Error(getMessage(`[${label}]应为正数`)))
        if (!plus && value >= 0)
            return callback(new Error(getMessage(`[${label}]应为负数`)))
        if (dec && value % 1 !== 0 && declength === 0)
            return callback(new Error(getMessage(`[${label}]应为整数`)))
        if (intlength && int && int.length > intlength)
            return callback(
                new Error(getMessage(`[${label}]最多输入${intlength}位整数`))
            )
        if (declength && dec && dec.length > declength)
            return callback(
                new Error(getMessage(`[${label}]最多输入${declength}位小数`))
            )
        if (equal) {
            if (max !== 0 && value > max)
                return callback(new Error(getMessage(`[${label}]的值应小于等于${max}`)))
            if (min !== 0 && value < min)
                return callback(new Error(getMessage(`[${label}]的值应大于等于${min}`)))
        } else {

            if (max !== 0 && value >= max)
                return callback(new Error(getMessage(`[${label}]的值应小于${max}`)))
            if (min !== 0 && value <= min)
                return callback(new Error(getMessage(`[${label}]的值应大于${min}`)))
        }
    }
    if (polling) polling()

    return callback()
}

export const validatorRepeat = (value = '', props, itemConfig, callback) => {
    console.log('validatorRepeat', value, itemConfig)
    return callback()
}
export const validatorImages = (value = '', props, callback) => {
    // 定义默认值
    const {
        label = '该项',
        required = false, //默认非必填
        message = '', //默认错误提示信息
    } = props
    function getMessage(params) {
        return message ? message : params
    }
    // 是否必填
    if (required) {
        if (
            (!value && Object.prototype.toString.call(value) !== '[object Number]') ||
            value.toString().length == 0
        )
            return callback(new Error(getMessage(`[${label}]为必传项`)))
    } else {
        if (value === '' || value === null || value === undefined) return callback()
    }
    return callback()
}
/*
函数：时间差计算
说明：完工时间是否大于开工时间，返回boolen
参数：starTime开始时间，endTime完工时间，示例："2019-04-09 12:06:10"
调用：calculateTime(starTime,endTime)
返回值：endTime-startTime > 0 的结果 true or false
*/
export const calculateTime = (startTime, endTime) => {
    let startTimestamp = new Date(startTime).valueOf()
    let endTimestamp = new Date(endTime).valueOf()
    if (endTimestamp - startTimestamp >= 0) {
        return true
    } else {
        return false
    }
}
//防抖节流
export const debounce = function (func, delay) {
    let timer

    return function (...args) {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            func.apply(this, args)
        }, delay || 500)
    }
}

//   时间检验
export const validatorTime = (value = '', props, callback) => {
    const {
        label = '该项',
        times = false,
        required = true,
        max = 0,
        min = 0,
        message = '',
        polling,
        calculator = false,
    } = props

    function getMessage(params) {
        return message ? message : params
    }

    if (required) {
        if (!max || !min) {
            return callback(new Error(getMessage(`[${label}]为必填项`)))
        }
    } else {
        if (value === '' || value === null || value === undefined) return callback()
    }

    if (times) {
        // console.log('validatorTime', value, props, max, min)
        // 不取秒之后只取前8位时间戳做时分的判断
        let Max = (max + '').substring(0, 8)
        let Min = (min + '').substring(0, 8)
        if (Number(Max) <= Number(Min) && calculator) {
            return callback(new Error('工时必须大于0'))
        }
    }

    if (polling) polling()

    return callback()
}
// 批量下载
export const downloadBulkFile = (url) => {
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none' // 防止影响页面
    iframe.style.height = 0 // 防止影响页面
    iframe.src = url
    document.body.appendChild(iframe) // 这一行必须，iframe挂在到dom树上才会发请求
    // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
    setTimeout(() => {
        iframe.remove()
    }, 5 * 60 * 1000)
}
// 导出
export const exportBulkFile = (baseUrl, queryForm, sfileName, pageNo, pageSize) => {
    const token = store.getters['user/token']
    let url = 'http://' + window.location.host + baseUrl
    let queryString = ''
    Object.keys(queryForm).forEach(key => {
        queryString += `${key}=${queryForm[key]}&`
    })
    if (queryString) { // id=1&xxx=abc&
        // 去除最后的&
        queryString = queryString.substring(0, queryString.length - 1)
        // 接到url
        url += '?' + queryString
    }
    let params = Object.assign({}, queryForm)
    params.page = pageNo
    params.page_size = pageSize
    axios({
        url: url,
        method: 'post',
        responseType: 'blob', //此处注意请求头
        headers: {
            'Content-Type': 'application/json',
            token: token,
        },
        data: params,
    }).then((res) => {
        const content = res.data
        const blob = new Blob([content])
        const fileName = sfileName
        if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
        } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
        }
    })
}


