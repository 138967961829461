var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "uploadForm",
      staticClass: "uploadForm",
      attrs: {
        "label-position": _vm.labelPosition,
        "label-width": _vm.labelWidth,
        model: _vm.uploadFileForm,
      },
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "upload-bg register-bg",
          attrs: {
            label: _vm.uploadLabel ? _vm.uploadItem.label + ":" : "",
            prop: _vm.uploadItem.prop,
            rules: [
              {
                required:
                  _vm.uploadItem.rules && _vm.uploadItem.rules.required
                    ? true
                    : false,
                validator: function (rule, value, callback) {
                  return _vm.validatorImages(
                    _vm.newList,
                    _vm.uploadItem.rules,
                    callback
                  )
                },
                trigger: ["blur", "change"],
              },
            ],
          },
        },
        [
          _c("div", { staticClass: "hide-video-box" }),
          _vm.mp4Config.url
            ? _c(
                "div",
                { staticClass: "mp4Config" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 12, md: 24, sm: 24, xl: 12, xs: 24 } },
                        [
                          _c(
                            "div",
                            { staticClass: "videoWriper" },
                            [
                              _c("vab-player-mp4", {
                                attrs: { config: _vm.mp4Config },
                                on: {
                                  player: function ($event) {
                                    _vm.$vabPlayerMp4 = $event
                                  },
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "videoRemove",
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "text" },
                                  on: { click: _vm.videoRemove },
                                },
                                [_vm._v(" 删除 ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "uploadImg" },
                [
                  _c(
                    "el-upload",
                    _vm._b(
                      {
                        ref: "upload",
                        staticClass: "uploadRef hide",
                        class: [
                          _vm.accessories.listType === "picture-card"
                            ? "avatar-uploader"
                            : "",
                          {
                            hideUpload:
                              _vm.hideUpload || _vm.pageState == "view",
                          },
                        ],
                        attrs: {
                          accept: _vm.accessories.accept,
                          action: _vm.upload_url,
                          "before-upload": _vm.beforeUpload,
                          data: _vm.accessories.fileParam,
                          disabled: _vm.accessories.disabled,
                          "file-list": _vm.newList,
                          headers: _vm.accessories.headers,
                          "http-request": _vm.uploadSectionFile,
                          limit: _vm.accessories.limit,
                          "list-type": _vm.accessories.listType,
                          multiple: _vm.accessories.multiple,
                          "on-change": _vm.uploadChange,
                          "on-error": _vm.error,
                          "on-exceed": _vm.handleExceed,
                          "on-success": _vm.success,
                          "show-file-list":
                            _vm.accessories.listType === "picture-card"
                              ? true
                              : false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _vm.accessories.listType === "picture-card"
                                  ? _c("i", { staticClass: "el-icon-plus" })
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.accessories.listType,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.accessories.labelName) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "file",
                            fn: function (ref) {
                              var file = ref.file
                              return [
                                _c("div", [
                                  _c("img", {
                                    staticClass:
                                      "el-upload-list__item-thumbnail",
                                    attrs: { alt: "", src: file.url },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-actions",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-preview",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handlePreview(file)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-zoom-in",
                                          }),
                                        ]
                                      ),
                                      _vm.pageState != "view"
                                        ? _c("span", [
                                            !_vm.accessories.disabled
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "el-upload-list__item-delete",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleRemove(
                                                          file
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-delete",
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      },
                      "el-upload",
                      _vm.$attrs,
                      false
                    )
                  ),
                ],
                1
              ),
          _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": "",
                "before-close": _vm.closePreviewDialog,
                "close-on-click-modal": false,
                size: "tiny",
                title: _vm.titlePreview,
                visible: _vm.dialogVisible[_vm.uploadItem.prop],
                width: "40%",
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(
                    _vm.dialogVisible,
                    _vm.uploadItem.prop,
                    $event
                  )
                },
              },
            },
            [
              _c("img", {
                staticClass: "imgPreviewDialog",
                attrs: { alt: "", src: _vm.currentImageUrl },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }