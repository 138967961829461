var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.theme.showRefresh
    ? _c("vab-icon", {
        attrs: { icon: "refresh-line" },
        on: { click: _vm.refreshRoute },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }