<template>
  <div class="vab-app-main">
    <section>
      <vab-router-view />
    </section>
    <vab-footer />
  </div>
</template>

<script>
  export default {
    name: 'VabAppMain',
  }
</script>
