<!--  -->
<template>
  <el-form
    ref="uploadForm"
    class="uploadForm"
    :label-position="labelPosition"
    :label-width="labelWidth"
    :model="uploadFileForm"
  >
    <el-form-item
      class="upload-bg register-bg"
      :label="uploadLabel ? uploadItem.label + `:` : ''"
      :prop="uploadItem.prop"
      :rules="[
        {
          required:
            uploadItem.rules && uploadItem.rules.required ? true : false,
          validator: (rule, value, callback) =>
            validatorImages(newList, uploadItem.rules, callback),
          trigger: ['blur', 'change'],
        },
      ]"
    >
      <div class="hide-video-box"></div>
      <div
        v-if="mp4Config.url"
        class="mp4Config"
      >
        <el-row :gutter="20">
          <el-col
            :lg="12"
            :md="24"
            :sm="24"
            :xl="12"
            :xs="24"
          >
            <div class="videoWriper">
              <vab-player-mp4
                :config="mp4Config"
                @player="$vabPlayerMp4 = $event"
              />
              <el-button
                class="videoRemove"
                style="margin-left: 10px"
                type="text"
                @click="videoRemove"
              >
                删除
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <div
        v-else
        class="uploadImg"
      >
        <!-- {{ itemCallBack.action }} -->
        <el-upload
          v-bind="$attrs"
          ref="upload"
          :accept="accessories.accept"
          :action="upload_url"
          :before-upload="beforeUpload"
          class="uploadRef hide"
          :class="[
            accessories.listType === 'picture-card' ? 'avatar-uploader' : '',
            { hideUpload: hideUpload || pageState == 'view' },
          ]"
          :data="accessories.fileParam"
          :disabled="accessories.disabled"
          :file-list="newList"
          :headers="accessories.headers"
          :http-request="uploadSectionFile"
          :limit="accessories.limit"
          :list-type="accessories.listType"
          :multiple="accessories.multiple"
          :on-change="uploadChange"
          :on-error="error"
          :on-exceed="handleExceed"
          :on-success="success"
          :show-file-list="accessories.listType === 'picture-card' ? true : false"
        >
          <template #default>
            <i
              v-if="accessories.listType === 'picture-card'"
              class="el-icon-plus"
            ></i>
            <el-button
              v-else
              :type="accessories.listType"
            >
              {{ accessories.labelName }}
            </el-button>
          </template>
          <template #file="{ file }">
            <div>
              <img
                alt=""
                class="el-upload-list__item-thumbnail"
                :src="file.url"
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span v-if="pageState != 'view'">
                  <!-- <span v-if="!accessories.disabled" class="el-upload-list__item-delete" @click="downloadImg(file.url)">
                    <i class="el-icon-download"></i>
                  </span> -->
                  <span
                    v-if="!accessories.disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </span>
            </div>
          </template>
        </el-upload>
      </div>
      <!-- 预览 -->
      <el-dialog
        append-to-body
        :before-close="closePreviewDialog"
        :close-on-click-modal="false"
        size="tiny"
        :title="titlePreview"
        :visible.sync="dialogVisible[uploadItem.prop]"
        width="40%"
      >
        <img
          alt=""
          class="imgPreviewDialog"
          :src="currentImageUrl"
        />
      </el-dialog>
    </el-form-item>
  </el-form>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import { httpBaseUrl } from '@/config'
import { uuid } from '@/utils'
import { validatorImages } from '@/project/eztree/baseTool/calculator'
export default {
  name: 'NxpUpload',
  components: {
  },
  // 不想继承所有父组件的内容,同时也不在组件根元素dom上显示属性
  inheritAttrs: true,
  props: {
    tableRow: {
      type: Object,
      default: () => {
        return {}
      }
    },
    pageState: {
      type: String,
      default: 'add'
    },
    accessories: {
      type: Object,
      default: () => {
        return {
          disabled: false,
        }
      }
    },
    labelPosition: {
      type: String,
      default: 'right'
    },
    labelWidth: {
      type: String,
      default: '0px'
    },
    uploadItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    uploadLabel: {
      type: Boolean,
      default: false
    },
  },
  data () {
    //这里存放数据
    return {
      uploadType: "",
      upload_url: '',
      onOffPreview: false,
      titlePreview: "",
      imgUrl: "",
      newList: [],
      uploadFileForm: {},
      checkValid: false,
      dialogVisible: {},
      currentImageUrl: '',
      $vabPlayerMp4: null,
      mp4Config: {
        id: 'mse1',
        url: '',
        volume: 0.6,
        autoplay: false,
        fluid: true,
        screenShot: false,
        pip: false,
        danmu: {
          comments: [
            {
              duration: 15000,
              id: uuid(),
              start: 3000,
              mode: 'scroll',
              style: {
                color: '#fff',
                fontSize: '14px',
                border: 'solid 1px #ff9500',
                borderRadius: '2px',
                padding: '2px 11px',
                backgroundColor: '#ff9500',
              },
            },
          ],
          area: {
            start: 0,
            end: 1,
          },
        },
      },
    }
  },
  computed: {
    hideUpload: function () {
      return this.newList.length >= this.accessories.limit ? true : false
    },
  },
  watch: {
    'uploadItem': {
      handler: function (newVal, oldV) {
        // 图片、文件回显

      },
      deep: true, //深度监听设置为 true
    },
    'newList': {
      handler: function (newVal, oldV) {
        if (newVal) {
          if (newVal.length > 0 && this.uploadItem.rules.required) {
            this.$refs['uploadForm'].clearValidate()
          }
        }
      },
      deep: true, //深度监听设置为 true
    },
  },
  mounted () {
    for (let key in this.uploadItem) {
      if (key == 'prop') {
        this.$set(this.dialogVisible, this.uploadItem[key], false)
      }
    }
  },
  methods: {
    validatorImages,
    resetValue () {
      this.newList = []
      this.$refs['uploadForm'].clearValidate()
    },
    callBackItemImg (val) {
      let downKeyArr = []
      let list = []
      if (val) {
        this.$nextTick(() => {
          let valueType = Object.prototype.toString.call(val)
          console.log(val, valueType);
          if (valueType == "[object String]") {
            downKeyArr = JSON.parse(val)
            if (downKeyArr != []) {
              downKeyArr.forEach((item, i) => {
                let suffix = item.substring(item.lastIndexOf('.') + 1)//后
                if (suffix == 'mp4') {
                  this.mp4Config.url = item
                }
                if (item != null) {
                  list.push({
                    url: item,
                    downKey: i
                  })
                }
              })
            }
            this.newList = list
          }
          if (valueType == "[object Array]") {
            this.newList = val.map((item, i) => {
              return {
                url: item,
                downKey: i
              }
            })
          }
        })
      }
    },
    uploadSubmit () {
      this.checkValid = true
      this.$emit('getUploadList', this.uploadItem, this.newList, this.tableRow)
    },
    validateSubmit () {
      if (this.uploadItem.rules.required) {
        this.$refs['uploadForm'].validate(valid => {
          this.checkValid = valid
          if (valid) {
            // console.log('图片校验通过1111', this.newList)
            this.$emit('getUploadList', this.uploadItem, this.newList, this.tableRow)
          } else {
            // console.log('图片校验不通过333', this.uploadItem)
          }
        })
      }
    },
    // 文件上传事件
    beforeUpload (file) {
      let s = file.name
      let suffix = s.substring(s.lastIndexOf('.') + 1)//后
      let exist = null
      let acceptArr = []
      if (this.accessories.accept != '') {
        acceptArr = this.accessories.accept.split(',')
        exist = acceptArr.findIndex(e => e == '.' + suffix.toLowerCase())
        if (exist == -1) {
          this.$message.warning(`上传不成功，只能上传` + this.accessories.accept + `文件`);
          return false
        }
      }
      //大小判断
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        this.$message.error('上传文件大小不能超过 100MB!');
        return false
      }
    },
    handleExceed () {
      this.$message.error(`最多上传` + this.accessories.limit + `个文件`);
    },

    uploadSectionFile: function (params) {
      console.log('httpBaseUrl', httpBaseUrl);
      var self = this,
        file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf('image') != -1,
        isVideo = fileType.indexOf('video') != -1,
        file_url = self.$refs.upload.uploadFiles[0].url;
      //   self.uploadType = fileType.substring(0, fileType.lastIndexOf("/"))//前
      if (this.accessories.listType == 'text') {
        //导入
        self.upload_url = httpBaseUrl + this.accessories.uploadUrl;//'你的图片上传URL'
        self.upload_name = 'file';
        self.uploadFile(file, isVideo, '');
        return
      }

      if (isImage) {
        //图片上传
        var img = new Image();
        img.src = file_url;
        img.onload = function () {
          self.upload_url = httpBaseUrl + '/user-center/upload';//'你的图片上传URL'
          self.upload_name = 'file';
          self.uploadFile(file, isVideo, '');
        }
        return
      }
      if (isVideo) {
        //视频上传
        var isMP4 = file.type === 'video/mp4';
        if (!isMP4) {
          self.$baseMessage('上传视频只支持 mp4 格式!', 'error', 'vab-hey-message-error')
          self.$refs.upload.uploadFiles = [];
          return;
        }
        var fileSize = file.size / 1024 / 1024 < 50;
        if (!fileSize) {
          layer.msg("视频大小不能超过50MB");
          return false;
        }
        var videoDiv = document.createElement('video');
        var liItem = document.getElementsByClassName('hide-video-box')[0];
        videoDiv.src = file_url;
        liItem.appendChild(videoDiv);
        videoDiv.onloadeddata = function (event) {
          self.upload_url = httpBaseUrl + '/user-center/upload';//'你的视频上传URL'
          self.upload_name = 'file';
          self.uploadFile(file, isVideo, videoDiv);
        }
      }

    },
    uploadFile: function (file, isVideo, videoDiv) {
      var self = this,
        formData = new FormData();
      formData.append(self.upload_name, file);
      const token = store.getters['user/token']
      axios.post(self.upload_url, formData, {
        headers: {
          'Content-Type': 'application/xml',
          'X-Requested-With': 'XMLHttpRequest',
          'token': token
        }
      }).then(function (res) {
        const { data } = res
        console.log(file.name, data.data);
        if (data.code == 200) {
          let arr = data.data
          let s = arr.file_name
          let suffix = s.substring(s.lastIndexOf('.') + 1)//后
          self.newList.push({
            "fileName": file.name,
            "suffix": suffix,
            "url": s,
            "uid": file.uid,
          });
          self.$emit('getUploadList', self.uploadItem, self.newList, self.tableRow)
          self.$message.success('上传成功')
          //创建一个显示video的容器
          if (isVideo) {
            var liItem = document.getElementsByClassName('el-upload-list__item')[0];
            videoDiv.style.width = '278px';
            videoDiv.style.height = '415px';
            liItem.prepend(videoDiv);
            self.mp4Config.url = self.$downFileWithKey + data.data
            self.mp4Config.uid = file.uid
          }
          return false
        }
        self.$baseMessage('上传失败', 'error', 'vab-hey-message-error')
        self.$refs.upload.uploadFiles = [];
      })
    },
    error (file) {
      this.$message.warning(`上传不成功，只能上传` + this.accessories.accept + `文件`);
    },
    success () {

    },
    uploadChange () { },
    handlePreview (file) {
      this.currentImageUrl = file.url
      this.dialogVisible[this.uploadItem.prop] = true
    },
    handleDownload (file) {

    },
    handleRemove (file) {
      if (file && file.status === 'success') {
        let index = this.newList.findIndex(e => e.downKey == file.downKey)
        this.newList.splice(index, 1);
      }
    },
    videoRemove () {
      let index = this.newList.findIndex(e => e.uid == this.mp4Config.uid)
      this.newList.splice(index, 1);

      var liItem = document.getElementsByClassName('hide-video-box')[0];
      liItem.innerHTML = '';
      this.mp4Config.url = ''
    },
    closePreviewDialog () {
      this.currentImageUrl = ''
      this.dialogVisible[this.uploadItem.prop] = false
    },
    // 图片下载
    downloadImg (imgUrl) {
      let num = imgUrl.lastIndexOf('/') + 1
      let name = imgUrl.substring(num)
      let file_name = name
      this.$message({
        type: "success",
        message: "下载成功"
      });
      console.log('downloadImg', imgUrl);
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = file_name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgUrl
    },
    // Pdf文件下载
    downloadFDF (item) {
      let herf = this.$downFileWithKey + item.downKey
      let file_name = item.fileName
      this.$message({
        type: "success",
        message: "下载成功"
      });
      var ajax = new XMLHttpRequest();
      ajax.open("GET", herf, true);
      ajax.setRequestHeader("Cache-Control", "no-cache")
      ajax.setRequestHeader('Access-Control-Allow-Origin', '*');
      ajax.responseType = 'blob';
      ajax.onload = e => {
        let res = e.target.response
        let blob = new Blob([res], {
          type: 'application/pdf'
        })
        FileSave.saveAs(blob, file_name);
      }
      ajax.send()
    },
  }
}
</script>
<style lang="scss" >
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.uploadRef {
  display: inline-block;
}

.hideUpload .el-upload--picture-card {
  display: none !important;
}

.hide-video-box {
  visibility: hidden;
  position: absolute;
  z-index: -999;
}

.imgPreviewDialog {
  width: 80%;
  display: flex;
  margin: 0 auto;
}

.uploadForm {
  display: inline-block;
}
</style>