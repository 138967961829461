var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nxpTable" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          ref: "multipleTableRef",
          staticClass: "mgrTable",
          attrs: {
            border: "",
            data: _vm.tableData,
            "element-loading-text": "拼命加载中",
            height: _vm.height - _vm.WTheight,
            "highlight-current-row": "",
            "row-class-name": _vm.tableRowClass,
            "row-key": _vm.onlyKeyId,
            size: "mini",
            "span-method": _vm.objectSpanMethod,
          },
          on: {
            select: _vm.handelSelect,
            "select-all": _vm.handelSelectAll,
            "selection-change": _vm.selectionChange,
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.accessories.pageType == "components"
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "",
                  "reserve-selection": true,
                  selectable: _vm.checkSelectable,
                  type: "selection",
                  width: "60",
                },
              })
            : _vm._e(),
          _vm.isShowIndex
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  index: _vm.formatIndex.index,
                  label: "序 号",
                  type: "index",
                  width: "60",
                },
              })
            : _vm._e(),
          _vm._l(_vm.tableColumns, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                align: item.align || "center",
                fixed: item.fixed,
                formatter: item.formatterType,
                label: item.label,
                prop: item.prop,
                "show-overflow-tooltip": item.tooltip ? true : false,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        item.render
                          ? _c("ex-slot", {
                              attrs: {
                                column: item,
                                index: scope.$index,
                                render: item.render,
                                row: scope.row,
                              },
                            })
                          : _c("span", [
                              _vm._v(_vm._s(scope.row[item.prop] || "")),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _vm.showBtn
        ? _c(
            "div",
            { staticClass: "moreBtn", staticStyle: { margin: "10px 0" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "leftBtn",
                  attrs: { disabled: _vm.btnChangeEnable, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.delRow("", "delete")
                    },
                  },
                },
                [
                  _vm._v(
                    " 批量删除" + _vm._s(_vm.chekedDataResult.length) + " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showPagination
        ? _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.accessories.pageNo,
              layout: _vm.layout,
              "page-size": _vm.accessories.pageSize,
              "page-sizes": _vm.pageSizes,
              total: _vm.accessories.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }