import request from '@/utils/request'

export function expireToken() {
  return request({
    url: '/expireToken',
    method: 'get',
  })
}

export function refreshToken() {
  return request({
    url: '/refreshToken',
    method: 'get',
  })
}
// code
export async function queryCode(data) {
    if (loginRSA) {
        data = await encryptedData(data)
    }
    return requests({
        url: dicAddress + '/dictInfo/queryPage',
        method: 'post',
        data
    })
}