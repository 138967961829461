<template>
  <div ref="content" class="searchList">
    <el-form ref="searchForm" :inline="true" :model="searchParam" @submit.native.prevent>
      <span v-for="(item, index) in configList" :key="index" class="search-item">
        <el-form-item :label="item.showName ? item.label : ''" :prop="item.param + index">
          <div class="mode">
            <!-- 输入框 -->
            <el-input
              v-if="item.type == 'text'"
              v-model.trim="item.model"
              class="width160"
              clearable
              :placeholder="`请输入` + item.label"
              @change="changeSelect(item.param, item.model)"
            />
            <!-- 下拉框 -->
            <el-select
              v-if="item.type == 'select'"
              v-model.trim="item.model"
              class="width160"
              clearable
              collapse-tags
              filterable
              :multiple="item.multiple"
              :placeholder="`请选择` + item.label"
              @change="changeSelect(item.param, item.model, item)"
            >
              <el-option
                v-for="(l, idx) in item.options"
                :key="l.label + '' + idx"
                :label="l.label"
                :value="l.value"
              />
            </el-select>

            <!-- 日期 -->
            <el-date-picker
              v-if="item.type == 'singleDate'"
              v-model.trim="item.model"
              clearable
              :default-time="item.defaultTime"
              :placeholder="`请选择` + item.label"
              type="date"
              :value-format="item.valueFormat || `yyyy-MM-dd HH:mm:ss`"
              @change="changeSelect(item.param, item.model)"
            />

            <el-date-picker
              v-if="item.type == 'datetimerange'"
              v-model.trim="item.model"
              clearable
              :default-time="item.defaultTime"
              end-placeholder="结束日期"
              :picker-options="is_pickerOptions ? pickerOptions : {}"
              start-placeholder="开始日期"
              type="datetimerange"
              :value-format="item.valueFormat || `yyyy-MM-dd HH:mm:ss`"
              @change="changeSelect(item.param, item.model)"
            />

            <el-date-picker
              v-if="item.type == 'daterange'"
              v-model.trim="item.model"
              align="right"
              clearable
              :default-time="item.defaultTime"
              end-placeholder="结束日期"
              :picker-options="is_pickerOptions ? pickerOptions : {}"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              unlink-panels
              :value-format="item.valueFormat || `yyyy-MM-dd`"
              @change="changeSelect(item.param, item.model)"
            />
          </div>
        </el-form-item>
      </span>

      <slot :name="slotName"></slot>
      <span class="search-item">
        <el-form-item>
          <el-button
            class="searchBtn"
            icon="el-icon-search"
            type="primary"
            @click="handelClickSearch"
          >
            查询
          </el-button>
          <el-button icon="el-icon-refresh" type="primary" @click="resetForm">
            重置
          </el-button>
        </el-form-item>
      </span>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "NxpSearch",
  components: {},
  props: {
    configList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    slotName: {
      type: String,
      default: "",
    },
    is_pickerOptions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchParam: {},
      nxpSearchParam: {
        list: [],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  watch: {
    configList: {
      handler: function (newV, oldV) {},
      deep: true, //深度监听设置为 true
    },
  },
  created() {
    this.searchConfigInit();
  },
  mounted() {},
  methods: {
    searchConfigInit() {
      this.configList.forEach((ele) => {
        let valueType = Object.prototype.toString.call(ele.model);
        if (valueType == "[object Null]") {
          ele.model = ele.model || null;
        }
        if (valueType == "[object String]") {
          ele.model = ele.model || "";
        }
        if (valueType == "[object Array]") {
          ele.model = ele.model || [];
        }
        this.searchParam[ele.param] = ele.model;
      });
      this.$emit("clickSearch", this.searchParam);
    },
    //  点击事件
    handelClickSearch() {
      this.$emit("clickSearch", this.searchParam);
    },
    // 改变事件
    changeSelect(type, value, eleName) {
      this.searchParam[type] = value;
    },

    // 重置
    resetForm() {
      // 重置要看 el-form-item 组件元素的 prop 上是否指定了字段名，指定了才会重置生效
      this.configList.forEach((ele, n) => {
        ele.model = "";
      });
      for (const key in this.searchParam) {
        if (this.searchParam[key] instanceof Array) this.searchParam[key] = [];
        else this.searchParam[key] = null;
      }
    },
  },
};
</script>

<style lang="scss">
.searchList {
  .width160 {
    width: 200px !important;
  }

  .searchBtn {
    margin-left: 0 !important;
  }

  .search-item {
    display: inline-block;

    .el-form-item {
      margin-bottom: 10px;

      .width160 {
        width: 180px !important;
      }
    }
  }

  .submitBtn {
    text-align: right;
  }
}
</style>
