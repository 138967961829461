import Vue from 'vue'
import App from './App'
import i18n from './i18n'
import store from './store'
import router from './router'
import './registerServiceWorker'
import '@/vab'
// import VueSocketio from "vue-socket.io";
//全局注册
import '@/components/registerComponents'
/**
 * @description 正式环境默认使用mock，正式项目记得注释后再打包
 */
import { httpBaseUrl, uploadURL } from './config'
import { isExternal } from '@/utils/validate'

console.log('HTTP_URL', process.env);
Vue.config.productionTip = false
// 图片预览
Vue.prototype.$downFileWithKey = uploadURL
// vue防重复点击(指令实现)
Vue.directive('noMoreClick', {
  inserted (el, binding, vnode) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled');
      el.disabled = true;
      setTimeout(() => {
        el.disabled = false;
        el.classList.remove('is-disabled');
      }, 3000)
    })
  }

})
// 解决路由replace到当前页面报错问题
const originalPush = router.replace
router.replace = function replace (location) {
  return originalPush.call(this, location).catch(err => err)
}
// Vue.use(
//   new VueSocketio({
//     // 生产环境建议关闭，开发环境打开(在控制台看到socket连接和事件监听的信息)
//     debug: process.env.NODE_ENV === 'development',
//     connection: httpBaseUrl,
//     options: {
//       // 创建时是否自动连接 我们这里设定为false,在指定页面再开启
//       autoConnect: false,
//       // 路径(默认值：/socket.io/)
//       path: "/my-app/",
//       // 目前有两种传输方式：HTTP long-polling（可简称：polling）、WebSocket
//       transports: ['polling'],
//       // 附加请求头（在服务器端的 socket.handshake.headers 对象中找到）
//       extraHeaders: {},
//     },
//     // 如果没有使用到 store 可不写
//     vuex: {
//       store,
//       actionPrefix: 'SOCKET_',// vuex action 前缀
//       mutationPrefix: 'SOCKET_', // vuex mutation 前缀
//     },
//   })
// )
new Vue({
  el: '#app',
  i18n,
  store,
  router,
  render: (h) => h(App),
})
