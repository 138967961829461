var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "vab-footer" },
    [
      _vm._v(" " + _vm._s(_vm.globalName) + " "),
      _c("vab-icon", { attrs: { icon: "copyright-line" } }),
      _c("a", { attrs: { href: "https://beian.miit.gov.cn/" } }, [
        _vm._v(_vm._s(_vm.globalRecord)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }