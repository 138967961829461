<template>
  <el-cascader
    :size="size"
    :options="options"
    v-model="provinces"
    @change="dynamicHandleChange"
    :placeholder="placeholder"
    :parameters="parameters"
  ></el-cascader>
</template>

<script>
export default {
  name: "cascader-component",
  props: {
    size: {
      type: String,
      default: "small", // 默认大小为small
    },
    options: {
      type: Array,
      default: () => [], // 默认选项数据为空数组
    },
    placeholder: {
      type: String,
      default: "请选择省市区", // 默认提示文字为"请选择省市区"
    },
    parameters: {
      type: String,
      default: "code", // 默认为code值
    },
    selectValue: String, //model值
  },
  data() {
    return {
      provinces: [], // 选中的省市区数据，默认为空数组
    };
  },
  watch: {
    selectValue: {
      handler(newValue) {
        // 确保选择框中的值已经更新完成
        this.$nextTick(() => {
          this.provinces = newValue;
        });
      },
      immediate: true,
    },
  },
  methods: {
    dynamicHandleChange(e) {
      if (this.parameters == "name") {
        this.$emit("change", this.ideograph(e));
      } else {
        this.$emit("change", this.figure(e));
      }
    },
    // 传递中文数据
    ideograph(code) {
      const areas = [];
      if (Array.isArray(code) && code.length) {
        let region = this.options;
        for (let i = 0; i < code.length; i++) {
          const item = code[i];
          const found = region.find((regionItem) => regionItem.value === item);
          if (found) {
            areas.push(found.label); // 直接将中文推入数组中
            region = found.children || [];
            if (i === code.length - 1) {
              // 最后一个区域，可以打印或进行其他处理
            }
          } else {
            break;
          }
        }
      }
      return areas.join("/");
    },
    // 传递code数据
    figure(code) {
      return code.join("/");
    },
  },
};
</script>
