<!--  -->
<template>
  <div class="nxpTable">
    <!-- 表格 -->
    <el-table
      ref="multipleTableRef"
      v-loading="tableLoading"
      border
      class="mgrTable"
      :data="tableData"
      element-loading-text="拼命加载中"
      :height="height - WTheight"
      highlight-current-row
      :row-class-name="tableRowClass"
      :row-key="onlyKeyId"
      size="mini"
      :span-method="objectSpanMethod"
      @select="handelSelect"
      @select-all="handelSelectAll"
      @selection-change="selectionChange"
    >
      <el-table-column
        v-if="accessories.pageType == 'components'"
        align="center"
        fixed
        :reserve-selection="true"
        :selectable="checkSelectable"
        type="selection"
        width="60"
      />
      <el-table-column
        v-if="isShowIndex"
        align="center"
        :index="formatIndex.index"
        label="序 号"
        type="index"
        width="60"
      />
      <el-table-column
        v-for="(item, index) in tableColumns"
        :key="index"
        :align="item.align || 'center'"
        :fixed="item.fixed"
        :formatter="item.formatterType"
        :label="item.label"
        :prop="item.prop"
        :show-overflow-tooltip="item.tooltip ? true : false"
        :width="item.width"
      >
        <template slot-scope="scope">
          <ex-slot
            v-if="item.render"
            :column="item"
            :index="scope.$index"
            :render="item.render"
            :row="scope.row"
          />
          <span v-else>{{ scope.row[item.prop] || '' }}</span>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <div
      v-if="showBtn"
      class="moreBtn"
      style="margin: 10px 0"
    >
      <el-button
        class="leftBtn"
        :disabled="btnChangeEnable"
        type="primary"
        @click="delRow('', 'delete')"
      >
        批量删除{{ chekedDataResult.length }}
      </el-button>
    </div>
    <!-- 分页 -->
    <el-pagination
      v-if="showPagination"
      background
      :current-page="accessories.pageNo"
      :layout="layout"
      :page-size="accessories.pageSize"
      :page-sizes="pageSizes"
      :total="accessories.total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
// 自定义内容的组件
var exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    column: {
      type: Object,
      default: null
    }
  },
  render: (h, data) => {
    const params = {
      row: data.props.row,
      index: data.props.index
    }
    if (data.props.column) params.column = data.props.column
    return data.props.render(h, params)
  }
}
export default {
  name: 'SelectTable',
  components: { exSlot },
  inheritAttrs: true,
  props: {
    'accessories': {
      type: Object,
      default: () => {
        return {
          pageType: "components",
          isAllSelect: true,
          selectType: "checkBox",
          pageNo: 1,
          pageSize: 15,
          total: 0,
          height: ''
        }
      }
    },
    'showBtn': {
      type: Boolean,
      default: false,
    },
    'mergeCol': {
      type: Boolean,
      default: false,
    },
    'showPagination': {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    'WTheight': {
      type: Number,
      default: 200,
    },
    'isShowIndex': {
      type: Boolean,
      default: true,
    },
    'tableLoading': {
      type: Boolean,
      default: false,
    },
    'tableData': {
      type: Array,
      default: () => {
        return []
      }
    },
    'tableColumns': {
      type: Array,
      default: () => {
        return []
      }
    },
    'onlyKeyId': {
      type: String,
      default: 'index'
    },
    // 回显
    'callbackSelection': {
      type: Array,
      default: () => {
        return []
      }
    },
    objectSpanMethod: {
      type: Function,
      default: () => { }
    },
  },

  data () {
    //这里存放数据
    let _this = this;
    return {
      btnChangeEnable: false,
      height: window.innerHeight,
      checkedAll: false,
      pageSizes: [15, 50, 100, 200],
      spanArr: [],
      position: 0,
      layout: 'total, sizes, prev, pager, next, jumper',
      chekedDataResult: [],
      formatIndex: {
        index (index) {
          return (_this.accessories.pageNo - 1) * _this.accessories.pageSize + index + 1
        }
      },
      selectionChange (rows) {
        // console.log('selectionChange', rows);
      },
      // 全选框点击事件
      handelSelectAll (rows) {
        console.log('handelSelectAll', rows);
        _this.checkedAll = rows.length > 0 ? true : false
        _this.tableData.forEach((row, i) => {
          let index = _this.chekedDataResult.findIndex(e => e[_this.onlyKeyId] == row[_this.onlyKeyId])
          if (_this.checkedAll) {
            row.flag = row.disabled ? false : true;
            if (index == -1 && row.flag) {
              _this.chekedDataResult.push(row)
            }
          } else {
            row.flag = false;
            _this.chekedDataResult.splice(_this.chekedDataResult[index], 1)
          }
        });
        // _this.$forceUpdate()
      },
      // 单击事件
      handelSelect (record, row, selectedRows) {
        if (row.disabled) {
          return false
        }
        row.flag = !row.flag
        let index = _this.chekedDataResult.findIndex(e => e[_this.onlyKeyId] == row[_this.onlyKeyId])
        if (row.flag && index == -1) {
          if (_this.accessories.selectType == 'radioBox') {
            _this.chekedDataResult = []
            _this.tableData.forEach((item, i) => {
              if (item[_this.onlyKeyId] !== row[_this.onlyKeyId]) {
                item.flag = false
              }
            })
          }
          _this.chekedDataResult.push(row)
        } else {
          _this.chekedDataResult.splice(index, 1)
        }
        // _this.$forceUpdate()
      },
    }
  },
  watch: {
    // 监听表格数据变化 做回显
    'tableData' (val, oldVal) {
      let key = this.onlyKeyId
      this.tableData.forEach((ele, i) => {
        ele.index = (this.accessories.pageNo - 1) * this.accessories.pageSize + i + 1
        this.chekedDataResult.forEach((m, index) => {
          if (m[key] == ele[key]) {
            // console.log('回显', m.id, ele.id);
            this.$nextTick(() => {
              this.$refs.multipleTableRef.toggleRowSelection(this.tableData[i], true)
            })
          }
        })
      })
      this.$nextTick(() => {
        this.$refs.multipleTableRef.bodyWrapper.scrollTop = 0;
        this.$refs.multipleTableRef.doLayout()
      })
    },

    'chekedDataResult' (val, oldVal) {
      console.log('chekedDataResult2', val)
      this.btnChangeEnable = val.length > 0 ? false : true
      if (val && !this.tableLoading) {
        this.$emit('fetchTableSelection', this.chekedDataResult)
      }
    },
  },
  created () {

  },
  mounted () {
    this.chekedDataResult = JSON.parse(JSON.stringify(this.callbackSelection))
  },
  methods: {
    tableRowClass (val) {
      if (val.row.rowBg == '1') {
        return 'warning-row';
      } else {
        return '';
      }
    },
    resetTable () {
      this.$refs.multipleTableRef.clearSelection()
      this.tableData.forEach((ele, i) => {
        ele.flag = false
      })
      this.chekedDataResult = []
    },
    // 禁止选择
    checkSelectable (row) {
      return !row.disabled //将disbaled为false
    },
    // 分页
    handleSizeChange (val) {
      this.$set(this.accessories, "pageSize", val)
      this.$emit('fetchData', this.accessories)
    },
    handleCurrentChange (val) {
      this.$set(this.accessories, "pageNo", val)
      this.$emit('fetchData', this.accessories)
    },
    delRow () {
      this.$confirm('确认删除已选择的吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('delSelect')
        this.resetTable()
      })
    },
  }
}
</script>
<style>
.moreBtn {
  position: absolute;
}
</style>