<template>
  <div class="goods-item tree-item-box boxshadow padded-10">
    <div class="pos-relative" @click="is_preview ? showView(item) : () => {}">
      <div class="demo-image__lazy">
        <el-image
          style="width: 100%; height: 180px"
          :key="item.cover_image"
          :src="item.cover_image"
          lazy
        />
      </div>
      <div
        v-if="(is_show_specification && item.xdj) || item.height || item.gf"
        class="pos-absolute pos-b0 w-per-100"
        style="background: rgba(0, 0, 0, 0.45)"
      >
        <div class="padded-6 fs-12 c-FFF spanEllipsis">
          <span>{{
            item.xdj_status == 1 && (item.xdj || item.xdj > 0)
              ? "Φ" + item.xdj
              : item.xdj_status == 2 && (item.xdj || item.xdj > 0)
              ? "D" + item.xdj
              : ""
          }}</span>
          <span>{{
            (item.xdj || item.xdj > 0) && (item.height || item.height > 0) ? "/" : ""
          }}</span>
          <span>{{ item.height || item.height > 0 ? "H" + item.height : "" }}</span>
          <span>{{
            (item.gf || item.gf > 0) && (item.height || item.height > 0) ? "/" : ""
          }}</span>
          <span>{{ item.gf || item.gf > 0 ? "P" + item.gf : "" }}</span>
        </div>
      </div>
    </div>
    <div class="font-bold padded-l10 padded-t10 spanEllipsis">
      <span>{{ item.item_name || item.tree_type_name }}</span>
    </div>
    <div class="padded-l10 padded-b6" v-if="is_show_price">
      <div class="flex-box-item padded-t6 tableCell spanEllipsis">
        <span class="fs-14 c-F30">{{
          !parseInt(item.price) ? "面议" : "￥" + parseInt(item.price)
        }}</span>
        <i class="el-icon-location-outline" color="#999" size="8"></i>
        <span class="fs-12 c-999">{{ item.location }}</span>
      </div>
      <slot name="number-box"></slot>
    </div>
    <div class="flex-box pos-relative padded-l10" v-if="is_show_updated">
      <div class="flex-box-item c-999 fs-12">{{ item.updated_at }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectTree",
  props: {
    item: {
      type: Object,
      default: null,
    },
    is_show_like: {
      type: Boolean,
      default: false,
    },
    is_show_price: {
      type: Boolean,
      default: true,
    },
    is_preview: {
      type: Boolean,
      default: false,
    },
    is_show_specification: {
      type: Boolean,
      default: true,
    },
    is_show_updated: {
      type: Boolean,
      default: false,
    },
    compose_type: {
      type: String | Number,
    },
    mounted_text: {
      type: String,
      default: "待上架",
    },
    is_shadow: {
      type: Boolean,
      default: true,
    },
    infoBackgroundColor: {
      type: String,
      default: "#fff",
    },
    is_show_shop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    showView(item) {
      this.$emit("showView", item);
    },
  },
};
</script>

<style scoped lang="scss">
.goods-item {
  transition: all 0.5s;
  &:hover {
    transform: translate3d(0, -3px, 0);
    box-shadow: 0 3px 8px rgb(0 0 0 / 20%);
  }

  img {
    width: 100%;
    height: 220px;
  }
}
</style>
