<template>
  <!-- <footer class="vab-footer">
    <span>{{ globalName }}</span>
    <a href="https://beian.miit.gov.cn/"> {{ globalRecord }}</a>
  </footer> -->
  <footer class="vab-footer">
    {{ globalName }}
    <vab-icon icon="copyright-line" />
    <a href="https://beian.miit.gov.cn/">{{ globalRecord }}</a>
  </footer>
</template>

<script>
import { globalName, globalRecord, fullYear } from '@/config'

export default {
  name: 'VabFooter',
  data() {
    return {
      globalName,
      globalRecord,
      fullYear,
    }
  },
}
</script>
<style lang="scss" scoped>
.vab-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 55px;
  padding: 0 $base-padding 0 $base-padding;
  color: rgba(0, 0, 0, 0.45);
  background: $base-color-white;
  border-top: 1px dashed $base-border-color;

  i {
    margin: 0 5px;
  }
}
</style>