/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/vab/layouts'
import { publicPath, routerMode } from '@/config'

Vue.use(VueRouter)
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    meta: {
      hidden: true,
      noKeepAlive: false,
    },
  },
  {
    path: '/agreement',
    component: () => import('@/views/agreement'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/privacy',
    component: () => import('@/views/privacy'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/callback',
    component: () => import('@/views/callback'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: {
      hidden: true,
    },
  },
]

export const asyncRoutes = [
  {
    path: '/',
    name: 'Root',
    component: Layout,
    meta: {
      title: '工作面板',
      icon: 'home-wifi-line',
    },
    children: [

      {
        path: 'index',
        name: 'User',
        component: () => import('@/project/eztree/page/User'),
        meta: {
          title: '用户列表',
          icon: 'shield-user-line',
          noClosable: true,
        },
      },
      {
        path: 'billboard',
        name: 'Billboard',
        component: () => import('@/project/eztree/page/Billboard'),
        meta: {
          title: '广告列表',
          icon: 'building-line',
          noClosable: true,
        },
      },
      {
        path: 'treeItem',
        name: 'TreeItem',
        component: () => import('@/project/eztree/page/TreeItem'),
        meta: {
          title: '苗木列表',
          icon: 'apps-line',
          noClosable: true,
        },
      },


      {
        path: 'treeVariety',
        name: 'TreeVariety',
        component: () => import('@/project/eztree/page/TreeVariety'),
        meta: {
          title: '苗单列表',
          icon: 'apps-line',
          noClosable: true,
        },
      },
      {
        path: 'project',
        name: 'Project',
        component: () => import('@/project/eztree/page/Project'),
        meta: {
          title: '项目列表',
          icon: 'projector-line',
          noClosable: true,
        },
      },
      {
        path: 'map',
        name: 'Map',
        component: () => import('@/project/eztree/page/Map'),
        meta: {
          title: '地图列表',
          icon: 'map-2-line',
          noClosable: true,
        },
      },
      {
        path: 'report',
        name: 'Report',
        component: () => import('@/project/eztree/page/Report'),
        meta: {
          title: '报告列表',
          icon: 'repeat-2-line',
          noClosable: true,
        },
      },

      {
        path: 'message',
        name: 'Message',
        component: () => import('@/project/eztree/page/Message'),
        meta: {
          title: '系统消息',
          icon: 'message-2-line',
          noClosable: true,
        },
      },
      {
        path: 'setting',
        name: 'Setting',
        component: () => import('@/project/eztree/page/Setting'),
        meta: {
          title: '参数配置',
          icon: 'mail-settings-line',
          noClosable: true,
        },
      },
    ],
  },
  {
    path: '/sys',
    name: 'Sys',
    component: Layout,
    meta: {
      title: '系统管理',
      icon: 'code-box-line',
    },
    children: [
      // {
      //   path: 'home',
      //   name: 'Home',
      //   component: () => import('@/project/eztree/page/Home'),
      //   meta: {
      //     title: '首页列表',
      //     icon: 'home-2-line',
      //     noClosable: true,
      //   },
      // },
      {
        path: 'treeTypeSelection',
        name: 'TreeTyppeSelection',
        component: () => import('@/project/eztree/page/TreeTyppeSelection'),
        meta: {
          title: '植物选型',
          icon: 'apps-line',
          noClosable: true,
        },
      },
      {
        path: 'treeItemImage',
        name: 'TreeItemImage',
        component: () => import('@/project/eztree/page/TreeItemImage'),
        meta: {
          title: '图片列表',
          icon: 'image-line',
          noClosable: true,
        },
      },
      {
        path: 'treeItemWater',
        name: 'TreeItemWater',
        component: () => import('@/project/eztree/page/TreeItemWater'),
        meta: {
          title: '水印列表',
          icon: 'image-line',
          noClosable: true,
        },
      },
      // {
      //   path: 'qrItemImage',
      //   name: 'QRItemImage',
      //   component: () => import('@/project/eztree/page/QRItemImage'),
      //   meta: {
      //     title: '二维码列表',
      //     icon: 'qr-code-line',
      //     noClosable: true,
      //   },
      // },
      {
        path: 'collect',
        name: 'Collect',
        component: () => import('@/project/eztree/page/Collect'),
        meta: {
          title: '一键号苗',
          icon: 'message-2-line',
          noClosable: true,
        },
      },
      {
        path: 'loginlog',
        name: 'Loginlog',
        component: () => import('@/project/eztree/page/Loginlog'),
        meta: {
          title: '登录日志',
          icon: 'error-warning-line',
          noClosable: true,
        },
      },
      {
        path: 'actionLog',
        name: 'ActionLog',
        component: () => import('@/project/eztree/page/ActionLog'),
        meta: {
          title: '操作日志',
          icon: 'error-warning-line',
          noClosable: true,
        },
      },
      {
        path: 'shareLog',
        name: 'ShareLog',
        component: () => import('@/project/eztree/page/ShareLog'),
        meta: {
          title: '分享日志',
          icon: 'error-warning-line',
          noClosable: true,
        },
      },
      {
        path: 'sms',
        name: 'Sms',
        component: () => import('@/project/eztree/page/Sms'),
        meta: {
          title: '短信日志',
          icon: 'message-line',
          noClosable: true,
        },
      },
      {
        path: 'logger',
        name: 'Logger',
        component: () => import('@/project/eztree/page/Logger'),
        meta: {
          title: '系统错误日志',
          icon: 'error-warning-line',
          noClosable: true,
        },
      },
      {
        path: 'password',
        name: 'Password',
        component: () => import('@/project/eztree/page/Password'),
        meta: {
          title: '更改密码',
          icon: 'lock-password-line',
          noClosable: true,
        },
      },
    ]
  }
]

const router = createRouter()

function fatteningRoutes (routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

export function resetRouter (routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.matcher = createRouter(routes).matcher
}

function createRouter (routes = constantRoutes) {
  return new VueRouter({
    base: publicPath,
    mode: routerMode,
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: routes,
  })
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
