<template>
  <div ref="content" class="searchList">111111</div>
</template>

<script>
export default {
  name: 'nxp-info',
  props: {
    configList: {
      type: Array,
      default: () => {
        return []
      },
    },
    slotName: {
      type: String,
      default: ''
    },
  },
  components: {},
  data() {
    return {
      searchParam: {},
      nxpSearchParam: {
        list: [
        ]
      },
    }
  },
  watch: {
    configList: {
      handler: function (newV, oldV) {
      },
      deep: true, //深度监听设置为 true
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {

  },
}
</script>

<style lang="scss">
</style>
