/**
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
            time = parseInt(time)
        }
        if (typeof time === 'number' && time.toString().length === 10) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    }
    return format.replace(/{([ymdhisa])+}/g, (result, key) => {
        let value = formatObj[key]
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
}

/**
 * @description 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */
export function formatTime(time, option) {
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000
    } else {
        time = +time
    }
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d) / 1000

    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
        return '1天前'
    }
    if (option) {
        return parseTime(time, option)
    } else {
        return (
            d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
        )
    }
}
// url参数处理
// var queryConfig = {
//     "page": "index",
//     "method": 2, //1:按照方法A查看 2:按照方法B查看
//     "subpage": -1, //-1:此条件不传递 a:查看contentA b:查看contentB
//     "spec_method": -1 //-1:此条件不传递 1:按照销量高低查 2:按照时间查
// };
export function setQueryConfig(queryConfig) {
    var _str = "";
    for (var o in queryConfig) {
        _str += o + "=" + queryConfig[o] + "&";
    }
    var _str = _str.substring(0, _str.length - 1);
    return _str;
}
export function getUrlParams(url) {
    const params = url.split("?")[1];
    const reg = /&?([^&]+)/g;
    let res = null;
    const map = Object.create(null);
    while (res = reg.exec(params)) {
        const paramEntry = res[1].split("=");
        map[paramEntry[0]] = paramEntry[1];
    }
    return map;
}
/**
 * @description 将url请求参数转为json格式
 * @param url
 * @returns {{}|any}
 */
export function paramObj(url) {
    const search = url.split('?')[1]
    if (!search) {
        return {}
    }
    return JSON.parse(
        '{"' +
        decodeURIComponent(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
            .replace(/\+/g, ' ') +
        '"}'
    )
}
// 截取参数
export function getQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
    var r = window.location.search.substr(1).match(reg)
    if (r !== null) return unescape(r[2]); return null
}
export function getFloatStr(num) {
    num += "";
    num = num.replace(/[^0-9|\.]/g, ""); //清除字符串中的非数字非.字符

    if (/^0+/)
        //清除字符串开头的0
        num = num.replace(/^0+/, "");
    if (!/\./.test(num))
        //为整数字符串在末尾添加.00
        num += ".0";
    if (/^\./.test(num))
        //字符以.开头时,在开头添加0
        num = "0" + num;
    num += "0"; //在字符串末尾补零
    num = num.match(/\d+\.\d{1}/)[0];
    return num;
}
/**
 * @description 父子关系的数组转换成树形结构数据
 * @param data
 * @returns {*}
 */
export function translateDataToTree(data) {
    const parent = data.filter(
        (value) => value.parentId === 'undefined' || value.parentId === null
    )
    const children = data.filter(
        (value) => value.parentId !== 'undefined' && value.parentId !== null
    )
    const translator = (parent, children) => {
        parent.forEach((parent) => {
            children.forEach((current, index) => {
                if (current.parentId === parent.id) {
                    const temp = JSON.parse(JSON.stringify(children))
                    temp.splice(index, 1)
                    translator([current], temp)
                    typeof parent.children !== 'undefined'
                        ? parent.children.push(current)
                        : (parent.children = [current])
                }
            })
        })
    }
    translator(parent, children)
    return parent
}
// ============菜单路由开始================
/**
 * @description 树形结构菜单数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */
export function translateMenuTreeToData(data, parentId, children) {
    const result = []
    data.forEach((item) => {
        const loop = (data) => {
            var s = data.uri
            let suffix = s ? s.substring(s.lastIndexOf('/') + 1) : ""//后
            result.push({
                uuid: data.uuid,
                parentUuid: data.parent_uuid && s ? data.parent_uuid : "0",
                path: suffix ? suffix : data.name,
                name: suffix ? suffix : data.name,
                // component: '@/nxp/page/global/' + suffix,
                // path: 'dashboard',
                // name: 'Dashboard',
                // component: '@/views/index/dashboard',
                meta: {
                    title: data.name,
                    icon: data.icon ? data.icon : '',
                },
            })
            const child = data[children]
            if (child) {
                for (let i = 0; i < child.length; i++) {
                    loop(child[i])
                }
            }
        }
        loop(item)
    })
    return result
}
// ============菜单路由结束================
/**
 * @description 树形结构数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */
export function translateTreeToData(data) {
    const result = []
    data.forEach((item) => {
        const loop = (data) => {
            result.push({
                id: data.id,
                name: data.name,
                parentId: data.parentId,
            })
            const child = data.children
            if (child) {
                for (let i = 0; i < child.length; i++) {
                    loop(child[i])
                }
            }
        }
        loop(item)
    })
    return result
}

/**
 * @description 10位时间戳转换
 * @param time
 * @returns {string}
 */
export function tenBitTimestamp(time) {
    const date = new Date(time * 1000)
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? '' + m : m
    let d = date.getDate()
    d = d < 10 ? '' + d : d
    let h = date.getHours()
    h = h < 10 ? '0' + h : h
    let minute = date.getMinutes()
    let second = date.getSeconds()
    minute = minute < 10 ? '0' + minute : minute
    second = second < 10 ? '0' + second : second
    return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
}

/**
 * @description 13位时间戳转换
 * @param time
 * @returns {string}
 */
export function thirteenBitTimestamp(time) {
    const date = new Date(time / 1)
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? '' + m : m
    let d = date.getDate()
    d = d < 10 ? '' + d : d
    let h = date.getHours()
    h = h < 10 ? '0' + h : h
    let minute = date.getMinutes()
    let second = date.getSeconds()
    minute = minute < 10 ? '0' + minute : minute
    second = second < 10 ? '0' + second : second
    return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
}

/**
 * @description 获取随机id
 * @param length
 * @returns {string}
 */
export function uuid(length = 32) {
    const num = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
    let str = ''
    for (let i = 0; i < length; i++) {
        str += num.charAt(Math.floor(Math.random() * num.length))
    }
    return str
}

/**
 * @description m到n的随机数
 * @param m
 * @param n
 * @returns {number}
 */
export function random(m, n) {
    return Math.floor(Math.random() * (m - n) + n)
}

/**
 * @description addEventListener
 * @type {function(...[*]=)}
 */
export const on = (function () {
    return function (element, event, handler, useCapture = false) {
        if (element && event && handler) {
            element.addEventListener(event, handler, useCapture)
        }
    }
})()

/**
 * @description removeEventListener
 * @type {function(...[*]=)}
 */
export const off = (function () {
    return function (element, event, handler, useCapture = false) {
        if (element && event) {
            element.removeEventListener(event, handler, useCapture)
        }
    }
})()

/**
 * @description 数组打乱
 * @param array
 * @returns {*}
 */
export function shuffle(array) {
    let m = array.length,
        t,
        i
    while (m) {
        i = Math.floor(Math.random() * m--)
        t = array[m]
        array[m] = array[i]
        array[i] = t
    }
    return array
}

export function data2treeDG(datas, dataArray) {
    for (let j = 0; j < dataArray.length; j++) {
        let dataArrayIndex = dataArray[j];
        let childrenArray = [];
        let Id = dataArrayIndex.id;
        for (let i = 0; i < datas.length; i++) {
            let data = datas[i];
            if (data.enabled == true) {
                let parentId = data.parentId;
                if (parentId == Id) {//判断是否为儿子节点
                    let objTemp = {
                        id: data.id,
                        name: data.name,
                        order: data.order,
                        parentId: parentId,
                    }
                    childrenArray.push(objTemp);
                }
            }
        }
        dataArrayIndex.children = childrenArray;
        if (childrenArray.length > 0) {//有儿子节点则递归
            this.data2treeDG(datas, childrenArray)
        }
    }
    this.setTree = dataArray;
    return dataArray;
}


/**
* @description 将扁平化数据 转换成 树状结构
* @param {Array} arrayList 扁平化的数据
* @param {String} pidStr parentId的key名
* @param {String} idStr id的key名
* @param {String} childrenStr children的key名
*/
export function fommatTree({ arrayList, pidStr = 'pid', idStr = 'id', childrenStr = 'children' }) {
    let listOjb = {}; // 用来储存{key: obj}格式的对象
    let treeList = []; // 用来储存最终树形结构数据的数组
    // 将数据变换成{key: obj}格式，方便下面处理数据
    for (let i = 0; i < arrayList.length; i++) {
        listOjb[arrayList[i][idStr]] = arrayList[i]
    }
    // 根据pid来将数据进行格式化
    for (let j = 0; j < arrayList.length; j++) {
        // 判断父级是否存在
        let haveParent = listOjb[arrayList[j][pidStr]]
        if (haveParent) {
            // 如果有没有父级children字段，就创建一个children字段
            !haveParent[childrenStr] && (haveParent[childrenStr] = [])
            // 在父级里插入子项
            haveParent[childrenStr].push(arrayList[j])
        } else {
            // 如果没有父级直接插入到最外层
            treeList.push(arrayList[j])
        }
    }
    return treeList
}

/**
 * 大数字转换，将大额数字转换为万、千万、亿等
 * @param value 数字值
 */
export function bigNumberTransform(value) {
    const newValue = ['', '', '']
    let fr = 1000
    let num = 3
    let text1 = ''
    let fm = 1
    while (value / fr >= 1) {
        fr *= 10
        num += 1
        // console.log('数字', value / fr, 'num:', num)
    }
    if (num <= 4) { // 千
        newValue[0] = parseInt(value / 1000) + ''
        newValue[1] = '千'
    } else if (num <= 8) { // 万
        text1 = parseInt(num - 4) / 3 > 1 ? '千万' : '万'
        // tslint:disable-next-line:no-shadowed-variable
        fm = text1 === '万' ? 10000 : 10000000
        if (value % fm === 0) {
            newValue[0] = parseInt(value / fm) + ''
        } else {
            newValue[0] = parseFloat(value / fm).toFixed(2) + ''
        }
        newValue[1] = text1
    } else if (num <= 16) { // 亿
        text1 = (num - 8) / 3 > 1 ? '千亿' : '亿'
        text1 = (num - 8) / 4 > 1 ? '万亿' : text1
        text1 = (num - 8) / 7 > 1 ? '千万亿' : text1
        // tslint:disable-next-line:no-shadowed-variable
        fm = 1
        if (text1 === '亿') {
            fm = 100000000
        } else if (text1 === '千亿') {
            fm = 100000000000
        } else if (text1 === '万亿') {
            fm = 1000000000000
        } else if (text1 === '千万亿') {
            fm = 1000000000000000
        }
        if (value % fm === 0) {
            newValue[0] = parseInt(value / fm) + ''
        } else {
            newValue[0] = parseFloat(value / fm).toFixed(2) + ''
        }
        newValue[1] = text1
    }
    if (value < 1000) {
        newValue[0] = value + ''
        newValue[1] = ''
    }
    return newValue.join('')
}
/**
 * 数据转换为树形（递归），示例：toTreeByRecursion(source, 'id', 'parentId', null, 'children')
 * @param {Array} source 数据
 * @param {String} idField 标识字段名称
 * @param {String} parentIdField 父标识字段名称
 * @param {Any} parentIdNoneValue 父级标识空值
 * @param {String} childrenField 子节点字段名称
 * @param {Object} treeOption tree树形配置
 */
// 调用时，字段名以字符串的形式传参，如treeData(source, 'id', 'parentId', 'children')
export function toTreeByRecursion(
    source = [],
    idField = 'id',
    parentIdField = 'parentId',
    parentIdNoneValue = '',
    childrenField = 'children',
    treeOption = undefined
) {
    const treeOptions = {
        enable: false, // 是否开启转tree插件数据
        keyField: 'key', // 标识字段名称，默认为key
        valueField: 'value', // 值字段名称，默认为value
        titleField: 'title', // 标题字段名称，默认为title

        keyFieldBind: 'id', // 标识字段绑定字段名称，默认为id
        valueFieldBind: 'id', // 值字段名称绑定字段名称，默认为id
        titleFieldBind: 'name' // 标题字段名称绑定字段名称，默认为name
    }
    // 合并tree树形配置
    if (treeOption) {
        Object.assign(treeOptions, treeOption)
    }

    // 对源数据深度克隆
    const cloneData = JSON.parse(JSON.stringify(source))
    return cloneData.filter(parent => {
        // 返回每一项的子级数组
        const branchArr = cloneData.filter(child => parent[idField] === child[parentIdField])

        // 绑定tree树形配置
        if (treeOptions.enable) {
            branchArr.map(child => {
                child[treeOptions.keyField] = child[treeOptions.keyFieldBind]
                child[treeOptions.valueField] = child[treeOptions.valueFieldBind]
                child[treeOptions.titleField] = child[treeOptions.titleFieldBind]
                return child
            })
        }

        // 如果存在子级，则给父级添加一个children属性，并赋值，否则赋值为空数组
        if (branchArr.length > 0) {
            parent[childrenField] = branchArr
        } else {
            parent[childrenField] = []
        }

        // 绑定tree树形配置
        if (treeOptions.enable) {
            parent[treeOptions.keyField] = parent[treeOptions.keyFieldBind]
            parent[treeOptions.valueField] = parent[treeOptions.valueFieldBind]
            parent[treeOptions.titleField] = parent[treeOptions.titleFieldBind]
        }

        return parent[parentIdField] === parentIdNoneValue // 返回第一层
    })
}

//银行卡号Luhn校验算法
//luhn校验规则：16位银行卡号（19位通用）: 
//1.将未带校验位的 15（或18）位卡号从右依次编号 1 到 15（18），位于奇数位号上的数字乘以 2。
//2.将奇位乘积的个十位全部相加，再加上所有偶数位上的数字。
//3.将加法和加上校验位能被 10 整除。

//bankno为银行卡号
export function luhnCheck(bankno) {
    var lastNum = bankno.substr(bankno.length - 1, 1);//取出最后一位（与luhn进行比较）

    var first15Num = bankno.substr(0, bankno.length - 1);//前15或18位
    var newArr = new Array();
    for (var i = first15Num.length - 1; i > -1; i--) {    //前15或18位倒序存进数组
        newArr.push(first15Num.substr(i, 1));
    }
    var arrJiShu = new Array();  //奇数位*2的积 <9
    var arrJiShu2 = new Array(); //奇数位*2的积 >9

    var arrOuShu = new Array();  //偶数位数组
    for (var j = 0; j < newArr.length; j++) {
        if ((j + 1) % 2 == 1) {//奇数位
            if (parseInt(newArr[j]) * 2 < 9)
                arrJiShu.push(parseInt(newArr[j]) * 2);
            else
                arrJiShu2.push(parseInt(newArr[j]) * 2);
        }
        else //偶数位
            arrOuShu.push(newArr[j]);
    }

    var jishu_child1 = new Array();//奇数位*2 >9 的分割之后的数组个位数
    var jishu_child2 = new Array();//奇数位*2 >9 的分割之后的数组十位数
    for (var h = 0; h < arrJiShu2.length; h++) {
        jishu_child1.push(parseInt(arrJiShu2[h]) % 10);
        jishu_child2.push(parseInt(arrJiShu2[h]) / 10);
    }

    var sumJiShu = 0; //奇数位*2 < 9 的数组之和
    var sumOuShu = 0; //偶数位数组之和
    var sumJiShuChild1 = 0; //奇数位*2 >9 的分割之后的数组个位数之和
    var sumJiShuChild2 = 0; //奇数位*2 >9 的分割之后的数组十位数之和
    var sumTotal = 0;
    for (var m = 0; m < arrJiShu.length; m++) {
        sumJiShu = sumJiShu + parseInt(arrJiShu[m]);
    }

    for (var n = 0; n < arrOuShu.length; n++) {
        sumOuShu = sumOuShu + parseInt(arrOuShu[n]);
    }

    for (var p = 0; p < jishu_child1.length; p++) {
        sumJiShuChild1 = sumJiShuChild1 + parseInt(jishu_child1[p]);
        sumJiShuChild2 = sumJiShuChild2 + parseInt(jishu_child2[p]);
    }
    //计算总和
    sumTotal = parseInt(sumJiShu) + parseInt(sumOuShu) + parseInt(sumJiShuChild1) + parseInt(sumJiShuChild2);

    //计算luhn值
    var k = parseInt(sumTotal) % 10 == 0 ? 10 : parseInt(sumTotal) % 10;
    var luhn = 10 - k;

    if (lastNum == luhn) {
        console.log("验证通过");
        return true;
    } else {
        console.log("银行卡号必须符合luhn校验");
        return false;
    }
}
/**
 * 将数值四舍五入后格式化成金额形式
 *
 * @param num 数值(Number或者String)
 * @param options 可选参数
 * @param options.prefix 金钱前缀，默认为空，一般为 ￥ 或 $
 * @param options.decimalNum 保留小数点个数，默认为2 一般为 0 1 2
 * @param options.splitSymbol 格式化分割符，默认为英文逗号，分隔符必须是单字符
 * @return 金额格式的字符串,如'￥1,234,567.45'
 * @type String
 */
export function formatCurrency(num, options = {}) {
    let { decimalNum, splitSymbol } = options;
    const { prefix = '￥' } = options;
    let centsPercent = 100;
    if (splitSymbol === undefined) splitSymbol = ',';
    if (decimalNum !== 0 && decimalNum !== 1 && decimalNum !== 2) decimalNum = 2;
    if (decimalNum === 0) centsPercent = 1;
    if (decimalNum === 1) centsPercent = 10;
    num = num.toString().replace(/\$|,/g, '');
    if (isNaN(num)) num = '0';
    const sign = (num === Math.abs(num).toString()) ? '' : '-';
    num = Math.abs(num);
    num = Math.floor((num * centsPercent) + 0.50000000001);
    let cents = num % centsPercent;
    num = Math.floor(num / centsPercent).toString();
    if (cents < 10 && decimalNum === 2) {
        cents = `0${cents}`;
    }
    for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
        const endPosition = (4 * i) + 3;
        num = num.substring(0, num.length - endPosition)
            + splitSymbol + num.substring(num.length - endPosition);
    }
    if (decimalNum === 0) {
        return prefix + sign + num;
    }
    return `${prefix}${sign}${num}.${cents}`;
}