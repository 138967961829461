var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vab-layout-column",
      class: {
        fixed: _vm.fixedHeader,
        "no-tabs-bar": !_vm.showTabs,
      },
    },
    [
      _c("vab-column-bar"),
      _c(
        "div",
        {
          staticClass: "vab-main",
          class:
            ((_obj = {}),
            (_obj["vab-main-" + _vm.theme.columnStyle] = true),
            (_obj["is-collapse-main"] = _vm.collapse),
            _obj),
        },
        [
          _c(
            "div",
            {
              staticClass: "vab-layout-header",
              class: {
                "fixed-header": _vm.fixedHeader,
              },
            },
            [
              _c("vab-nav"),
              _c("vab-tabs", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTabs,
                    expression: "showTabs",
                  },
                ],
              }),
            ],
            1
          ),
          _c("vab-app-main"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }