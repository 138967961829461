var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.theme.showNotice
    ? _c(
        "el-badge",
        { attrs: { value: _vm.badge } },
        [
          _c(
            "el-popover",
            {
              attrs: { placement: "bottom", trigger: "hover", width: "300" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "reference",
                    fn: function () {
                      return [
                        _c("vab-icon", {
                          attrs: { icon: "notification-line" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3189420217
              ),
            },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.translateTitle("通知"),
                        name: "notice",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "notice-list" },
                        [
                          _c("el-scrollbar", [
                            _c(
                              "ul",
                              _vm._l(_vm.list, function (item, index) {
                                return _c(
                                  "li",
                                  { key: index },
                                  [
                                    _c("el-avatar", {
                                      attrs: { size: 45, src: item.image },
                                    }),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(item.notice),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.translateTitle("邮件"),
                        name: "email",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "notice-list" },
                        [
                          _c("el-scrollbar", [
                            _c(
                              "ul",
                              _vm._l(_vm.list, function (item, index) {
                                return _c(
                                  "li",
                                  { key: index },
                                  [
                                    _c("el-avatar", {
                                      attrs: { size: 45, src: item.image },
                                    }),
                                    _c("span", [_vm._v(_vm._s(item.email))]),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "notice-clear",
                  on: { click: _vm.handleClearNotice },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" } },
                    [
                      _c("vab-icon", { attrs: { icon: "close-circle-line" } }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.translateTitle("清空消息"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }