/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import Vue from 'vue'
import { getUserInfo, pwdLogin, logout, socialLogin } from '@/project/eztree/baseApi/commonApi'
import { getToken, removeToken, setToken } from '@/utils/token'
import { getMyInfo, removeMyInfo, setMyInfo } from '@/utils/myInfo'
import { resetRouter } from '@/router'
import { isArray, isString } from '@/utils/validate'
import { title, tokenName } from '@/config'
import { paramObj, getUrlParams, getQueryString } from '@/utils'

import _ from 'lodash'

const state = () => ({
  myInfo: getMyInfo(),
  token: getToken(),
  username: localStorage.getItem('username'),
  avatar: localStorage.getItem('avatar'),
})
const getters = {
  myInfo: (state) => state.myInfo,
  token: (state) => state.token,
  username: (state) => state.username,
  avatar: (state) => state.avatar,
}
const mutations = {

  /**
 * @description 设置myInfo
 * @param {*} state
 * @param {*} myInfo
 */
  setMyInfo (state, myInfo) {
    state.myInfo = myInfo
    setMyInfo(myInfo)
  },

  /**
   * @description 设置token
   * @param {*} state
   * @param {*} token
   */
  setToken (state, token) {
    state.token = token
    setToken(token)
  },
  /**
   * @description 设置用户名
   * @param {*} state
   * @param {*} username
   */
  setUsername (state, username) {
    state.username = username
  },
  /**
   * @description 设置头像
   * @param {*} state
   * @param {*} avatar
   */
  setAvatar (state, avatar) {
    state.avatar = avatar
  },
}
const actions = {
  /**
   * @description 登录拦截放行时，设置虚拟角色
   * @param {*} { commit, dispatch }
   */
  setVirtualRoles ({ commit, dispatch }) {
    dispatch('acl/setFull', true, { root: true })
    commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
    commit('setUsername', 'admin(未开启登录拦截)')
  },
  /**
   * @description 登录
   * @param {*} { commit }
   * @param {*} paramInfo
   */
  async login ({ commit }, paramInfo) {
    const {
      data: { [tokenName]: token_data },
    } = await pwdLogin(paramInfo)
    console.log('token', paramInfo);
    if (token_data) {
      // let aurl = `http://nxp-market-service.c331667cd37134243b3f557ac369e1067.cn-shanghai.alicontainer.com?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwOi8vZXp0cmVlLmNvbS5jbiIsImF1ZCI6Imh0dHA6Ly9lenRyZWUuY29tLmNuIiwiaWF0IjoxNjU1NDU3MzgyLCJuYmYiOjE2NTU0NTczODIsInJvbGUiOiJhZG1pbiIsImV4cCI6MTY2NDA5NzM4Mn0.SeI4leqjOxqo6RDtnxzgEBtPi36o5Z1q5Kw8E3Ap7t4`
      // console.log('getQuery', getUrlParams(aurl));
      // let tokenLocal = getUrlParams(aurl).token
      commit('setToken', token_data)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8
          ? '早上好'
          : hour <= 11
            ? '上午好'
            : hour <= 13
              ? '中午好'
              : hour < 18
                ? '下午好'
                : '晚上好'
      // Vue.prototype.$baseNotify(`欢迎登录${title}`, `${thisTime}！`)
    } else {
      const err = `登录接口异常，未正确返回${tokenName}...`
      Vue.prototype.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    }
  },


  /**
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  async getUserInfo ({ commit, dispatch }) {
    const {
      data: { username, avatar, roles, ability },
    } = await getUserInfo()
    /**
     * 检验返回数据是否正常，无对应参数，将使用默认用户名,头像,Roles和Ability
     * username {String}
     * avatar {String}
     * roles {List}
     * ability {List}
     */
    if (
      (username && !isString(username)) ||
      (avatar && !isString(avatar)) ||
      (roles && !isArray(roles)) ||
      (ability && !isArray(ability))
    ) {
      const err = 'getUserInfo核心接口异常，请检查返回JSON格式是否正确'
      Vue.prototype.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    } else {
      // 如不使用username用户名,可删除以下代码
      if (username) commit('setUsername', username)
      // 如不使用avatar头像,可删除以下代码
      if (avatar) commit('setAvatar', avatar)
      // 如不使用roles权限控制,可删除以下代码
      if (roles) dispatch('acl/setRole', roles, { root: true })
      // 如不使用ability权限控制,可删除以下代码
      if (ability) dispatch('acl/setAbility', ability, { root: true })
    }
  },
  /**
   * @description 退出登录
   * @param {*} { dispatch }
   */
  async logout ({ dispatch }) {
    // await logout()
    await dispatch('resetAll')
  },
  /**
   * @description 重置token、roles、ability、router、tabsBar等
   * @param {*} { commit, dispatch }
   */
  async resetAll ({ commit, dispatch }) {
    commit('setUsername', '游客')
    commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
    commit('routes/setRoutes', [], { root: true })
    await dispatch('setToken', '')
    await dispatch('setMyInfo', '')
    await dispatch('acl/setFull', false, { root: true })
    await dispatch('acl/setRole', [], { root: true })
    await dispatch('acl/setAbility', [], { root: true })
    await dispatch('tabs/delAllVisitedRoutes', null, { root: true })
    await resetRouter()
    removeToken()
    removeMyInfo()
  },

  /**
  * @description 设置myInfo
  * @param {*} { commit }
  * @param {*} myInfo
  */
  setMyInfo ({ commit }, myInfo) {
    commit('setMyInfo', myInfo)
  },
  /**
   * @description 设置token
   * @param {*} { commit }
   * @param {*} token
   */
  setToken ({ commit }, token) {
    commit('setToken', token)
  },
  /**
   * @description 设置头像
   * @param {*} { commit }
   * @param {*} avatar
   */
  setAvatar ({ commit }, avatar) {
    commit('setAvatar', avatar)
    localStorage.setItem('avatar', avatar)
  },
  /**
   * @description 设置用户名
   * @param {*} { commit }
   * @param {*} avatar
   */
  setUsername ({ commit }, username) {
    commit('setUsername', username)
    localStorage.setItem('username', username)
  },
}
export default { state, getters, mutations, actions }
